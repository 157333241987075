// 扩展原有功能:全局组件，自定义指令，挂载原型方法
import XtxSkeleton from './xtx-skeleton.vue'
import XtxCarousel from './xtx-carousel.vue'
import XtxMore from './xtx-more.vue'
import DefaultImg from '@/assets/images/200.png'

export default {
  install (app){
    app.component(XtxSkeleton.name,XtxSkeleton)
    app.component(XtxCarousel.name,XtxCarousel)
    app.component(XtxMore.name,XtxMore)
    //定义指令
    defineDirective(app)
  }
}

//定义指令
const defineDirective = (app) => {
  //图片懒加载指令  v-lazy
  app.directive('lazy',{
    mounted(el,binding) {
      // console.log('mounted');
      //创建一个观察对象，来观察当前使用指令的元素
      const observe = new IntersectionObserver(([{isIntersecting}]) => {
        if (isIntersecting) {
          //停止观察
          observe.unobserve(el)
          // console.log('进入可视区');
          //把指令的值设置给el的src属性 binding.value就是指令的值
          //处理图片加载失败  onerror是图片加载失败的事件 load  图片加载成功的事件
          el.onerror = () => {
            el.src = DefaultImg
          }
          el.src = binding.value
        }
      },{
        threshold:0
      })
      //开启观察
      observe.observe(el)
    },
  })
}
