//分类模块
import {
  topCategory
} from '@/api/constants'
import {
  findAllCategory
} from '@/api/category'

export default {
  namespaced: true,
  state() {
    return {
      //分类信息集合，依赖topCategory重新设置
      List: topCategory.map(item => ({
        name: item
      }))
    }
  },
  mutations: {
    setList(state, payload) {
      state.list = payload
    },
    // 修改当前一级分类下的open数据为true
    show(state,id){
      const currCategory = state.list.find(item => item.id === id)
      currCategory.open = true
    },
    // 修改当前一级分类下的open数据为false
    hide(state,id){
      const currCategory = state.list.find(item => item.id === id)
      currCategory.open = false
    }

  },
  actions: {
    async getList({ commit }) {
      //获取分类数据,修改
      const { result } = await findAllCategory()
      result.forEach(item => {
        item.open = false
      })
      commit('setList',result)
    }
  }
}
