//定义分类相关API接口

import request from '@/utils/request'

/**
 * 获取所有分类(顶级，二级，对应的推荐商品)
 * @returns Promise
*/
export const findAllCategory = () => {
  return { code:'1', message: 'ok', result: [] }
  // return request('/home/category/head','get')
}
