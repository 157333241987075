import { createApp } from 'vue'
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import '@/assets/styles/common.less'
import '@/assets/styles/main.css'
import UI from '@/components/library/index'

import ElementPlus from 'element-plus'
import 'element-plus/theme-chalk/index.css'

import i18n from './common/i18n'

createApp(App).use(i18n).use(store).use(router).use(UI).use(ElementPlus).mount('#app')
