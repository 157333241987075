export default {
  lang: {
    homeOverview:'首页概览',
    firmOverview:'公司概述',
    firmReports:'财务报表',
    firmAppendix:'更多附录',
    firmIndex:'主要财务指标',
    firmAnalysis:'对比分析',
    firmNews:'新闻事件档案',
    firmOther:'其他功能',
  }
}
