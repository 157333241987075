import {
  createRouter,
  createWebHashHistory
} from 'vue-router'

const routes = [{
  path: '/',
  component: () => import('@/views/Layout.vue'),
  children: [
      {
      path: '',
      name: 'home',
      component: () => import('@/views/home'),
    },
    {
      path:'/category/:id',
      component: () => import('@/views/category')
    },
    {
      path:'/category/sub/:id',
      component: () => import('@/views/category/sub')
    },
    {
      path:'/product/:id',
      component: () => import('@/views/product_v2')
    },
    {
      path:'/notify/:ret',
      component: () => import('@/views/notify')
    },
    {
      path:'/order/search',
      component: () => import('@/views/product/order')
    },
    {
      path:'/order/refund',
      component: () => import('@/views/product/refund')
    },
    {
      path:'/about/us',
      component: () => import('@/views/aboutus')
    },
    {
      path:'/productV2',
      component: () => import('@/views/product')
    },
    {
      path:'/order/companyinfo',
      component: () => import('@/views/product/companyinfo')
    },
    {
      path:'/instructions/explain',
      component: () => import('@/views/instructions/explain')
    },
    {
      path:'/instructions/manual',
      component: () => import('@/views/instructions/manual')
    }
  ]
}]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
