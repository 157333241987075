<template>
  <div class="app">
    <router-view />
  </div>
</template>

<script>
export default {
  name: "App",
  setup() {
    return {
    }
  }
};
</script>

<style lang="less"></style>
