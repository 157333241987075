import axios from 'axios'
import store from '@/store'
import router from '@/router'

//导出基准地址，原因：其他地方不是通过axios发请求的地方也要用上基准机制
// export const baseURL = 'http://123.56.106.178:9527/noneed/'
export const baseURL = 'https://www.security-pay.cn/noneed/'

//  1  创建一个新的axios实例
const instance = axios.create({
  //axios配置
  baseURL,
  timeout: 5000
})
//  2  请求拦截器，如果有token进行头部携带
instance.interceptors.request.use((config) => {
  //拦截业务逻辑
  //经行请求配置的修改
  //如果本地有token就在头部携带
  //  1  获取用户信息对象
  const { profile } = store.state.user
  //  2  判断是否有token
  if (profile.token) {
    //  3  设置token'
    config.headers.Authorization = `Bearer ${profile.token}`
  }
  return config
}, (err) => {
  return Promise.reject(err)
})

//  3  响应拦截器，1 剥离无效数据  2 处理token失效
instance.interceptors.response.use((response) => {
    return response.data
  }, (err) => {
    //401 ,进入该函数
    if (err.response && err.response.status === 401) {
      // 1  清空无效用户信息
      store.commit('user/setUser', {})

      // 2  跳转到登录页面
      //  3  跳转需要传参（当前路由地址）给登录页
      //router.currentRoute是响应式数据
      //encodeURIComponent 转换url编码，防止解析地址出问题
      const fullPath = encodeURIComponent(router.currentRoute.value.fullPath)
      router.push('/login?redirectUrl=' + fullPath)

    }
    return Promise.reject(err)
  }

)


//请求工具函数
const request = (url,method,submitData) => {
  //发送请求
  return instance({
    url,
    method,
    //  如果是get请求 ，需要用params来传递submitData
    //  如果不是get请求 ，需要用data来传递submitData
    [method.toLowerCase() === 'get'?'params':'data']:submitData
  })
}

//  4   到处一个函数调用当前的axios实例，发请求,返回值promise
export default request
