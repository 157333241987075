export default {
  lang: {
    homeOverview:'Home overview',
    firmOverview:'firmOverview',
    firmReports:'firmReports',
    firmAppendix:'firmAppendix',
    firmIndex:'firmIndex',
    firmAnalysis:'firmAnalysis',
    firmNews:'firmNews',
    firmOther:'firmOther'
  }
}
