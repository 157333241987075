//购物车模块
export default {
  namespaced: true,
  state() {
    return {
      //购物车商品列表
      list: []
    }
  }
}
